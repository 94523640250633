import { observable, action } from 'mobx'

class Store {
	@observable staffInfo = {}
	@observable token = ''
	@observable collapsed = false
	@observable loading = false
	@observable selectedKeys = []
	@observable bagOrderCode = ''
	@observable orderCode = ''
	@observable menuList = []
	@observable isSuper = false
	@observable inWhiteList = false
	@observable wxWork = false
	@observable corpStaffId = ''

	@action.bound
	onCollapse() {
		this.collapsed = !this.collapsed
	}

	@action.bound
	logout() {
		this.token = ''
		this.menuList = []
		this.isSuper = false
		this.loading = false
	}

	@action.bound
	showLoading() {
		this.loading = true
	}

	@action.bound
	hideLoading() {
		this.loading = false
	}

	@action.bound
	updateToken(token = '') {
		this.token = token || ''
	}

	@action.bound
	updateSelectedKeys(key = '') {
		this.selectedKeys = [key]
	}

	@action.bound
	updateBagOrderCode(code = '') {
		this.bagOrderCode = code || ''
	}

	@action.bound
	updateOrderCode(code = '') {
		this.orderCode = code || ''
	}

	@action.bound
	updateMenuList(list = []) {
		this.menuList = list
	}

	@action.bound
	updateIsSuper(isSuper = false) {
		this.isSuper = isSuper
	}

	@action.bound
	updateWhiteListStatus(status = false) {
		this.inWhiteList = status
	}

	@action.bound
	updateEnv(isWxWork = false) {
		this.wxWork = isWxWork
	}

	@action.bound
	updateCorpStaffId(corpStaffId = '') {
		this.corpStaffId = corpStaffId
	}
}

export default new Store()
