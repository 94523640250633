import React, { Component } from "react";
import { Spin, Icon } from "antd";
import "./Bundle.less";

const LoadingIcon = (
  <Icon type="loading-3-quarters" style={{ fontSize: 24 }} spin />
);

Spin.setDefaultIndicator(LoadingIcon);

class Bundle extends Component {
  state = {
    // short for "module" but that's a keyword in js, so "mod"
    mod: null
  };

  UNSAFE_componentWillMount() {
    this.load(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.load !== this.props.load) {
      this.load(nextProps);
    }
  }

  async load(props) {
    this.setState({
      mod: null
    });
    const { default: mod } = await props.load();
    this.setState({
      mod: mod.default || mod
    });
  }

  render() {
    return this.state.mod ? (
      this.props.children(this.state.mod)
    ) : (
      <div className="loading">
        <Spin size="large" />
      </div>
    );
  }
}

export default Bundle;
