import React, { Component } from 'react'
import { storage, WX_CONFIG } from '../../libs/conf'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { Util } from '../../libs/util'
import { UserService } from '../../libs/api'

@inject('store')
@withRouter
@observer
class WxWorkAuth extends Component {
  componentDidMount() {
    this.fetchAuth()
  }

  /**
   * @desc 企业微信第三方授权code
   */
  fetchAuth = () => {
    const loginUrl = `${window.location.href}`
    window.location.href = this.initWxAuthorizationBindUrl(loginUrl)
  }

  /**
   * @desc props更新
   * @param nextProps
   * @param nextContext
   * @returns {Promise<void>}
   * @constructor
   */
  async UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    // 检测到更新时，读取location中的参数，进行授权登录
    const { search } = nextProps.location
    const query = Util.getParams(search)
    const { code, state } = query || {}
    if (code) {
      await this.corpLogin({ code, state })
    }
  }

  /**
   * @desc 第三方授权登录
   * @param params
   * @returns {Promise<void>}
   */
  corpLogin = async (params = {}) => {
    const res = await UserService.corpLogin(params)
    try {
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.authInfo, JSON.stringify({ ...data }))
        const { updateToken, updateCorpStaffId } = this.props.store
        updateToken(data.accountVo.token || '')
        updateCorpStaffId(data.corpStaffId || '')
        this.props.updateLogin()
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 微信/企业微信授权
   * @param returnUrl
   * @param scope
   * @param state
   * @returns {string}
   */
  initWxAuthorizationBindUrl = (returnUrl, scope = 'snsapi_base', state = 'login') => {
    // const redirectUri = `${WX_CONFIG.WX_AUTH_DOMAIN}?returnUrl=${encodeURIComponent(returnUrl)}`;
    const url = `${WX_CONFIG.WX_OPEN_DOMAIN}/connect/oauth2/authorize?appid=${WX_CONFIG.WX_APPID}` +
      `&redirect_uri=${encodeURIComponent(returnUrl)}&response_type=code` +
      `&scope=${scope}&state=${state}#wechat_redirect`
    console.log(url)
    return url
  }

  render() {
    return (
      <div
        style={{
          padding: 20,
          textAlign: 'center'
        }}
      >
        企业微信授权中...
      </div>
    )
  }
}

export default WxWorkAuth
