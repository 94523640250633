import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { HashRouter as Router } from 'react-router-dom'
import App from './components/App'
import store from './store'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import './index.less'
const stores = { store }

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider {...stores}>
      <Router basename="/">
        <App />
      </Router>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)
