import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'
import {withRouter} from 'react-router-dom'
import Bundle from '../components/common/Bundle'
import {Util} from '../libs/util'
import {storage} from '../libs/conf'
import {observable, action} from 'mobx'
import WxWorkAuth from './layout/WxWorkAuth'

const Login = (isMobile) => (
  <Bundle load={() => import('../views/Login')}>
    {(Login) => <Login isMobile={isMobile}/>}
  </Bundle>
)

const Main = () => (
  <Bundle load={() => import('./layout/Main')}>{(Main) => <Main/>}</Bundle>
)

const MobileMain = () => (
  <Bundle load={() => import('./layout/MobileMain')}>
    {(MobileMain) => <MobileMain/>}
  </Bundle>
)

@inject('store')
@withRouter
@observer
class App extends Component {
  @observable isMobile = false
  @observable status = 'LOGIN'

  UNSAFE_componentWillMount() {
    this.checkWxWork()
    this.checkMobile()
    this.checkToken()
  }

  componentWillUnmount() {
    this.checkToken = null
  }

  @action.bound
  checkMobile = () => {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.isMobile = true
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }

  @action.bound
  checkWxWork = () => {
    const isWxWork = /(wxwork)/i.test(navigator.userAgent)
    const {updateEnv} = this.props.store
    updateEnv(isWxWork)
  }

  checkToken() {
    let authInfo = Util.getLocalStorage(storage.authInfo) || '{}'
    authInfo = JSON.parse(authInfo)
    const token =
      (authInfo && authInfo.accountVo && authInfo.accountVo.token) || ''
    if (token) {
      const {updateToken} = this.props.store
      updateToken(token)
    }
  }

  updateLogin = () => {
    this.status = 'SUCCESS'
  }

  render() {
    const {token, wxWork} = this.props.store
    const container =  <Main/>
    let renderCom = null
    if (wxWork) {
      if (this.status === 'LOGIN') {
        renderCom = <WxWorkAuth updateLogin={this.updateLogin}/>
      }

    } else {
      if (token) {
        renderCom = container
      } else {
        renderCom = <Login isMobile={this.isMobile}/>
      }
    }
    return renderCom
  }
}

export default App
