import { Util } from './util'

const { location } = window
const { href } = location

const envOptions = {
	// STAGING: 'https://api-test.kangarooread.com/',
	STAGING: 'https://api.kangarooread.com/',
	PROD: 'https://api.kangarooread.com/',
	CDNTEST: 'https://cdn-test.kangarooread.com/',
	CDNPROD: 'https://cdn.kangarooread.com/'
}

const prodReg = /^((http|https):\/\/crm\.kangarooread\.com)/
const isProd = prodReg.test(href)

const serverEnv = isProd ? envOptions.PROD : envOptions.STAGING

const cdnServer = isProd ? envOptions.CDNPROD : envOptions.CDNTEST

const env = {
	DOMAIN: `${serverEnv}v1`,
	APIVERSION: 'v1',
	DOMAIN_V2: `${serverEnv}v2`,
	CDN: cdnServer
}

const headerConfig = {
	platform: 7, // crm
	appVersion: '1.0.0',
	protocolVersion: 2,
	deviceId: Util.getDeviceId(),
	bigSmarter: {
		action: function () {
			return Util.setMD5(`${this.deviceId}_${this.platform}_${this.appVersion}`)
		}
	}
}

const OSS_UPLOAD_HOST = {
	host: `https://kangaroo-read-cdn${
		isProd ? '' : '-test'
	}.oss-cn-hangzhou.aliyuncs.com/`,
	cdn: `https://cdn${isProd ? '' : '-test'}.kangarooread.com/`
}

const OSS_DIR = {
	campSubCourse: 'resource/campSubCourse/'
}

const storage = {
	authInfo: 'auth_info',
	changeStatus: 'normal'
}

const roleMap = [
	{ value: 1, label: '会员' },
	{ value: 2, label: '推广人' },
	{ value: 3, label: '阅读分会' },
	{ value: 4, label: '服务中心' },
	{ value: 5, label: '机构' }
]

const credentialAccess = {
	READ_ONLY: 1,
	LIST: 2,
	WRITE_ONLY: 3,
	READ_LIST: 10,
	READ_LIST_WRITE: 11
}

const recruitingUpdateType = [
	{ value: 1, label: '买卡升级' },
	{ value: 2, label: '特殊升级' },
	{ value: 3, label: '2680升级' },
	{ value: 4, label: '降级' }
]

const subStatus = [
	{ value: 1, label: '订阅中', type: 'processing' },
	{ value: 2, label: '未订阅', type: 'default' },
	{ value: 3, label: '过期', type: 'default' },
	{ value: 4, label: '完结', type: 'success' },
	{ value: 5, label: '冻结/无效', type: 'error' },
	{ value: 6, label: '未激活', type: 'default' }
]

const WX_CONFIG = {
	WX_OPEN_DOMAIN: 'https://open.weixin.qq.com',
	WX_AUTH_DOMAIN: `${serverEnv}v1/thirdparty/corp/corp_crm_auth`,
	WX_APPID: 'wwea4cf0e58d1db319'
}

const UPGRADE_TYPE = [
	{ value: 0, label: '26800升级' },
	{ value: 1, label: '买卡升级' },
	{ value: 2, label: '特殊升级' },
	{ value: 3, label: '2680升级' },
	{ value: 4, label: '降级' },
	{ value: 5, label: '任务升级' }
]
const _CON_STATUS = [
	{ value: 1, label: '年费会员', color: '#FEC620' },
	{ value: 2, label: '2680故事妈妈,', color: '#F9625A' },
	{ value: 3, label: '20张卡推广人', color: '#AE61FD' },
	{ value: 4, label: '50张卡分会长', color: '#45CCCC' },
	{ value: 5, label: '100张卡分会长', color: '#10B48E' },
	{ value: 6, label: '服务中心', color: '#477CFD' },
	{ value: 7, label: ' 暂无意向', color: '#BBBBBB' }
]
const REMIND_STATUS = [
	{ value: 10, label: '未提醒', type: 'default' },
	{ value: 0, label: '已取消', type: 'error' },
	{ value: 50, label: '已提醒', type: 'success' }
]
const AWARDL_LIST = [
	{ value: 1, label: '线上收益' },
	{ value: 2, label: '线下收益' },
	{ value: 3, label: '谷粒数' }
]
const STATUS_LIST = [
	{ value: 0, label: '已使用' },
	{ value: 10, label: '待使用' }
]

const AWAROD_TYPE = [
	{ value: 1, label: '购卡返利', color: '#f16d7a' },
	{ value: 2, label: '招募佣金', color: '#ae716e' },
	{ value: 3, label: '训练营返利', color: '#f1ccb8' },
	{ value: 4, label: '阶梯返利', color: '#b7d28d' },
	{ value: 5, label: '提现', color: '#d9b8f1' },
	{ value: 6, label: '稿费', color: '#c2819f ' },
	{ value: 7, label: '津贴/薪资', color: '#94bfd5' },
	{ value: 8, label: '奖金', color: '#ddc159' },
	{ value: 9, label: '线下收益', color: '#a7ddec' },
	{ value: 10, label: '谷粒使用', color: '#e7a2bb' },
	{ value: 11, label: '谷粒购买', color: '#a2d8d2' }
]

const LEAD_LEVEL = [
	{ value: 1, label: '一星领读者', color: 'green' },
	{ value: 2, label: '二星领读者', color: 'blue' },
	{ value: 3, label: '三星领读者', color: 'geekblue' },
	{ value: 4, label: '四星领读者', color: 'purple' },
	{ value: 5, label: '五星领读者', color: 'gold' },
	{ value: 6, label: '领读者导师', color: 'red' }
]

const TIME_FORMAT = {
	FULL: 'YYYY-MM-DD HH:mm:ss',
	Y_M_D: 'YYYY-MM-DD'
}

const REFER_STATUS = [
	{ value: 0, label: '小程序注册绑定' },
	{ value: 1, label: 'APP注册绑定' },
	{ value: 2, label: '加库存未激活' },
	{ value: 3, label: '激活' },
	{ value: 4, label: '兑换码兑换绑定' },
	{ value: 5, label: '引流付费' },
	{ value: 6, label: '正价课程' },
	{ value: 7, label: '长期绑定' },
	{ value: 8, label: '过期' },
	{ value: 9, label: '预签约' },
	{ value: 10, label: '签约' },
	{ value: 11, label: '签约扣费' },
	{ value: 12, label: '关系变更' }
]

export {
	env,
	storage,
	headerConfig,
	roleMap,
	credentialAccess,
	recruitingUpdateType,
	subStatus,
	WX_CONFIG,
	UPGRADE_TYPE,
	_CON_STATUS,
	REMIND_STATUS,
	AWARDL_LIST,
	STATUS_LIST,
	AWAROD_TYPE,
	TIME_FORMAT,
	OSS_UPLOAD_HOST,
	OSS_DIR,
	LEAD_LEVEL,
	REFER_STATUS
}
