import fetch from './fetch'

const CommonService = {
	/**
	 * @desc 登录
	 * @param {*} params
	 */
	processLogin(params = {}) {
		return fetch('/user/app_login/by_phone', {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * 发送验证码
	 * @param {*} params
	 * type 1 -> 身份验证（用在的登录之后发验证码，目前我们没有用到）
	 *      2 -> 登录
	 *      3 -> 异地登录
	 *      4 -> 注册新用户
	 *      5 -> 忘记密码
	 */
	sendCode(params = {}) {
		return fetch('/thirdparty/ali/sms/code/send', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * 校验手机号
	 * @param {*} phone
	 */
	checkPhone(phone = '') {
		return fetch(`/user/crm/user/check_user/${phone}`, {
			headers: {
				v2: true
			}
		})
	},

	demoApi() {
		return fetch('/test/base64_image')
	},

	getAuthFile(path = '') {
		return fetch('/thirdparty/resource/get_access_url', {
			params: { path }
		})
	},

	/**
	 * @desc OSS 获取签名
	 * @param path
	 * @returns {undefined}
	 */
	getOSSData(path = '') {
		return fetch('/thirdparty/common/get_upload_path_url', {
			params: { path }
		})
	},

	/**
	 * @desc 获取oss文件预览授权
	 * @param {*} path
	 * @param {*} access
	 */
	getCredential({ path = '', access = 1 } = {}) {
		return fetch('/thirdparty/resource/get_credential', {
			params: { path, access }
		})
	},

	/**
	 * @desc 获取所有团队用户动态信息
	 * @param {*} params
	 */
	getUserDynamic(params = {}) {
		return fetch('/user/crm/user_dynamic/all_member', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取所有异常用户
	 * @param {*} params
	 */
	getAllAbnormalUser(params = {}) {
		return fetch('/user/crm/user_dynamic/all_abnormal_user', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取所有的下载任务
	 * @param {*} params
	 */
	getDownloadTask(params = {}) {
		return fetch('/thirdparty/ali/update_down_load_task', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取关系记录列表
	 * @param userId
	 * @returns {*}
	 */
	getUserReferRecord(userId = '') {
		return fetch('/user/crm/user/get_user_refer_record', {
			params: { userId },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取用户订阅信息
	 * @param params
	 * @returns {*}
	 */
	getUserServiceList(params = {}) {
		return fetch('/user/crm/user/get_user_service_list', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取即将到期列表
	 * @param params
	 * @returns {*}
	 */
	getExpireList(params = {}) {
		return fetch(`/user/crm/user/to_be_expire_list`, {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 软绑定
	 * @param {*} params
	 * @returns
	 */
	getTempBindingList(params = {}) {
		return fetch('/user/crm/user/temp_binding_list', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取会员列表
	 * @param params
	 * @returns {*}
	 */
	getReaderMemberList(params = {}) {
		return fetch('/user/crm/user/my_member', {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取服务详情
	 * @param id
	 * @returns {*}
	 */
	getUserServiceDetail(id = '') {
		return fetch('/user/crm/user/get_user_service_detail', {
			params: { id },
			headers: {
				v2: true
			}
		})
	}
}

const DistributionService = {
	/**
	 * @desc 获取组织架构关系
	 */
	getTeamRelationDirect(userId = '') {
		return fetch('/distribution/crm/team/affiliation/team_relation_direct', {
			params: { userId }
		})
	},

	/**
	 * @desc 获取首页看板的卡片数据
	 */
	getDashboardCardData(userId = '') {
		return fetch('/distribution/crm/reader/member/team_statistics', {
			params: { userId },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取直属列表头部信息
	 * @param {*} role
	 */
	getDirectHeader(role = '') {
		return fetch(`/distribution/crm/team/affiliation/get_direct_header/${role}`)
	},

	/**
	 * @desc 获取看板收益信息
	 */
	getAwardInfo() {
		return fetch('/distribution/crm/award/homepage', {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取组织信息
	 * @param {*} organizationId
	 */
	getOrganizationInfo(organizationId = '') {
		return fetch('/distribution/crm/organization/organization_info', {
			params: { organizationId }
		})
	},

	/**
	 * @desc 新增组织信息
	 * @param {*} params
	 */
	submitOrganization(params = {}) {
		return fetch('/distribution/crm/organization/submit', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 更新组织信息
	 * @param {*} params
	 */
	updateOrganization(params = {}) {
		return fetch('/distribution/crm/organization/update', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @desc 获取活跃用户头部信息
	 * @param level
	 */
	getActiveUserInfo(level = '') {
		return fetch('/distribution/crm/reader/member/get_active', {
			params: { level },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取招募推广人管理头部信息
	 * @param {*} date
	 */
	getAttractPartnerHeader(date = '') {
		return fetch('/distribution/crm/team/manage/attract/partner/header', {
			params: { date }
		})
	},

	/**
	 * @desc 获取招募推广人管理列表
	 * @param {*} params
	 */
	getAttractPartnerList(params = {}) {
		return fetch('/distribution/crm/team/manage/attract/partner/list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取订阅卡管理头部信息
	 */
	getCardStockHeader() {
		return fetch('/distribution/crm/card/stock/v1/get_card_stock_header')
	},

	/**
	 * @desc 获取订阅卡管理列表数据
	 * @param {*} params
	 */
	getCardStockList(params = {}) {
		return fetch('/distribution/crm/card/stock/v1/get_card_stock_list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取新人动销头部列表
	 */
	getNewcomerOnSaleHeader() {
		return fetch('/distribution/crm/card/stock/v1/newcomer_on_sale_header')
	},

	/**
	 * @desc 获取新人动销列表
	 * @param {*} params
	 */
	getNewcomerOnSaleList(params = {}) {
		return fetch('/distribution/crm/card/stock/v1/newcomer_on_sale_list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取对账列表
	 * @param {*} params
	 */
	getTieredConfirmList(params = {}) {
		return fetch('/distribution/crm/team/manage/tiered_confirm_list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取阶梯头部列表
	 * @param {*} date
	 */
	getTieredList(date = '') {
		return fetch('/distribution/crm/team/manage/tiered_list', {
			params: { date }
		})
	},

	/**
	 * @desc 获取收益明细
	 */
	referAwardInfoList() {
		return fetch('/distribution/mp/award/award_info_list')
	},

	/**
	 * @desc 获取GET用户转化相关列表
	 * @param {*} [params={}]
	 * @returns
	 */
	getGetUserHeadList(params = {}) {
		return fetch('/distribution/crm/active/get_user_head_list', {
			params: { ...params }
		})
	},

	/**
	 * @desc 获取get用户转化头部信息
	 * @returns
	 */
	getGetUserHeadInfo(campaignId = '') {
		return fetch('/distribution/crm/active/get_user_head_info', {
			params: { campaignId }
		})
	},

	/**
	 * @desc 导出直属列表
	 * @param {number} [role=2]
	 * @returns
	 */
	exportDirectList(role = 2) {
		return fetch('/distribution/crm/download/direct_list', {
			params: { role }
		})
	},

	/**
	 * @desc 导出GET用户列表
	 */
	exportGetUserList() {
		return fetch('/distribution/crm/download/get_user_list')
	},

	/**
	 * @desc 根据类型获取标签列表
	 * @param {number} [type=3]
	 * @returns
	 */
	getDisTagListByType(type = 3) {
		return fetch(`/distribution/crm/remark/dis_tag_list_by_type/${type}`, {
			method: 'POST'
		})
	},

	/**
	 * @description  实习领读者
	 * @param params
	 * @returns {*}
	 */
	getInternMemberList(params = {}) {
		return fetch(`/distribution/crm/reader/member/page/intern_list`, {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description  实习领读者详情
	 * @param params
	 * @returns {*}
	 */
	getInternRevenueDetail(params = {}) {
		return fetch(`/distribution/crm/performance/page/intern_revenue_detail`, {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 导出阶梯对账列表
	 * @param date
	 * @returns {undefined}
	 */
	exportTieredList(date = '') {
		return fetch('/distribution/crm/download/tiered_list', {
			params: { date }
		})
	},

	/**
	 * @description 根据月份获取业绩
	 * @param {*} params
	 */
	getSalesRankByDate(params = {}) {
		return fetch('/distribution/crm/card/stock/v1/sales_rank', {
			params: { ...params }
		})
	},

	/**
	 * @description 更新日志
	 * @param {*} params
	 */
	getAllUpdateLog(params = {}) {
		return fetch('/distribution/crm/chart/all_update_log', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 导出业绩
	 * @param {*} params
	 */
	downloadSalesRank(params = {}) {
		return fetch('/distribution/crm/download/sales_rank', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取活动数据看板列表
	 * @param params
	 * @returns {*}
	 */
	getRedashActiveList(params = {}) {
		return fetch('/distribution/crm/active/activity_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取用户详情标签列表
	 * @param params
	 * @returns {*}
	 */
	getUserDetailTagList(params = {}) {
		return fetch('/distribution/crm/remark/dis_tag_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取用户备注详情
	 * @param userId
	 * @returns {*}
	 */
	getMemberRemarkDetail(userId = '') {
		return fetch(`/distribution/crm/remark/member_remark_detail/${userId}`)
	},

	/**
	 * @description 更新用户备注相关信息
	 * @param params
	 * @returns {*}
	 */
	updateMemberRemarkInDetail(params = {}) {
		return fetch('/distribution/crm/remark/add_member_remark', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取用户参与的所有课程
	 * @param userId
	 * @returns {*}
	 */
	getUserCampList(userId = '') {
		return fetch(`/distribution/crm/active/user_camp_list/${userId}`, {
			// headers: {
			//   'v2': true
			// }
		})
	},

	/**
	 * @description 获取课程学动态
	 * @param params
	 * @returns {*}
	 */
	getCampDynamicList(params = {}) {
		return fetch('/distribution/crm/active/camp_dynamic_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取最近添加提醒列表
	 * @param {*} params
	 */
	getRecentlyRemindList(params) {
		return fetch('/distribution/crm/reminder/recently_remind_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取提醒列表
	 * @param {*} params
	 */
	getRemindList(params = {}) {
		return fetch('/distribution/crm/reminder/remind_list', {
			params: { ...params }
		})
	},
	/**
	 * @description 添加提醒
	 * @param {*} params
	 */
	handleReminder(params = {}) {
		return fetch('/distribution/crm/reminder/add_remind', {
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @description 相关提醒
	 * @param {*} userId
	 */
	getRelatedRemindlist(userId = '') {
		return fetch(`/distribution/crm/reminder/related_remind_list/${userId}`)
	},
	/**
	 *备注详情
	 * @param {*} remarkId
	 */
	getRemarkDetail(remarkId = '') {
		return fetch(`/distribution/crm/remark/remark_detail/${remarkId}`)
	},
	/**
	 * @description  删除提醒
	 * @param {*} remindId
	 */
	deteleRemind(remindId = '') {
		return fetch(`/distribution/crm/reminder/cancel_remind/${remindId}`)
	},

	/**
	 * @description 获取自定义标签列表
	 * @returns {*}
	 */
	getCreatorTagList() {
		return fetch('/distribution/crm/remark/creator_tag')
	},

	/**
	 * @description 自定义标签管理新增/更新标签
	 * @returns {*}
	 */
	addDisTagInUserDetail(params = {}) {
		return fetch('/distribution/crm/remark/add_dis_tag', {
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @description  收益明细列表
	 * @param {*} params
	 */
	getAwardList(params = {}) {
		return fetch('/distribution/crm/award/award_list', {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 收益记录详情
	 * @param awardRecordId
	 * @returns {*}
	 */
	getAwardRecordInfo(awardRecordId = '') {
		return fetch(`/distribution/crm/award/award_record_info/${awardRecordId}`, {
			headers: {
				v2: true
			}
		})
	},
	/**
	 * @description 收益纵览
	 * @return {*}
	 */
	getAwardOverview(month = '') {
		return fetch('/distribution/crm/award/award_overview', {
			params: { month },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 正价课招募情况
	 * @param date
	 * @returns {*}
	 */
	getAttractWorthyCourse(date = '') {
		return fetch('/distribution/crm/team/attract/worthy_course', {
			params: { date },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description
	 * @param month
	 * @returns {*}
	 */
	getAchieveOverview(month = '') {
		return fetch(`/distribution/crm/award/achieve_overview/${month}`)
	},

	/**
	 * @description 获取正价课招募列表
	 * @param params
	 * @returns {*}
	 */
	worthyCourseList(params = {}) {
		return fetch(`/distribution/crm/team/attract/worthy_course_list`, {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取绩效列表
	 * @param params
	 * @returns {*}
	 */
	getTeamAchieveList(params = {}) {
		return fetch('/distribution/crm/award/team_achieve_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取用户绩效记录
	 * @param params
	 * @returns {*}
	 */
	getUserAchieveList(params = {}) {
		return fetch('/distribution/crm/award/user_achieve_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 导出正价课列表
	 * @param params
	 * @returns {*}
	 */
	exportWorthyCourseList(params = {}) {
		return fetch('/distribution/crm/download/worthy_course_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取收益占比饼图
	 * @param date
	 * @returns {*}
	 */
	getChartOfAwardTypePercent(date = '') {
		return fetch('/distribution/crm/chart/award_type_percent', {
			params: { date },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取总收入趋势
	 * @returns {*}
	 */
	getChartOfRevenueTrendLineChart() {
		return fetch('/distribution/crm/chart/revenue_trend_line_chart', {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 导出线上收益
	 * @param params
	 * @returns {*}
	 */
	exportOnlineAwardList(params = {}) {
		return fetch('/distribution/crm/download/award_list', {
			params: { ...params }
		})
	},
	/**
	 * @description 导出收益明细
	 * @param params
	 * @returns {*}
	 */
	exportAchieveList(params = {}) {
		return fetch(`/distribution/crm/download/team_achieve_list`, {
			params: { ...params }
		})
	},

	/**
	 * @description 2021分会长游学活动
	 * @param params
	 * @returns {*}
	 */
	studyTourAchieveList(params = {}) {
		return fetch(`/distribution/crm/award/study_tour_achieve`, {
			params: { ...params }
		})
	},

	/**
	 * @description 导出游学列表
	 * @param params
	 * @returns {*}
	 */
	studyTourAchieveDownLoad() {
		return fetch(`/distribution/crm/download/study_tour_achieve`)
	},

	/**
	 * @description 业绩占比饼图
	 * @param userId
	 * @returns {*}
	 */
	achieveTrendLineChart(userId = '') {
		return fetch(`/distribution/crm/chart/achieve_trend_line_chart/${userId}`)
	},

	/**
	 * @description 业绩占比饼图
	 * @param params
	 * @returns {*}
	 */
	achieveTrendPieChart(params = {}) {
		return fetch(`/distribution/crm/chart/achieve_type_percent`, {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 创作家助教列表
	 * @param params
	 * @returns {*}
	 */
	getCreatorAndAssistant(params = {}) {
		return fetch(`/distribution/crm/team/member/creator_assistant`, {
			params: { ...params }
		})
	},

	/***
	 * @description  团队出卡明细
	 * @param params
	 * @returns {*}
	 */
	getCardSalesRecord(params = {}) {
		return fetch(`/distribution/crm/team/attract/card_sales_record`, {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 导出团队出卡明细
	 * @param params
	 * @returns {*}
	 */
	exportCardSalesRecord(params = {}) {
		return fetch(`/distribution/crm/download/card_sales_record`, {
			params: { ...params }
		})
	},

	/**
	 * @description 获取资深领读者列表
	 * @param params
	 * @returns {*}
	 */
	getSeniorReaderList(params = {}) {
		return fetch(`/distribution/crm/reader/member/senior_reader_list`, {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取团队信息
	 * @param userId
	 * @returns {*}
	 */
	getTeamService(userId = '') {
		return fetch(`/distribution/crm/reader/member/get_reader_team_info`, {
			params: { userId },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取团队销售列表
	 * @param params
	 * @returns {*}
	 */
	getReaderTeamSaleList(params = {}) {
		return fetch(
			`/distribution/crm/reader/member/get_reader_team_sale_record`,
			{
				params: { ...params },
				headers: {
					v2: true
				}
			}
		)
	},

	/**
	 * @description 获取销售记录详情
	 * @returns {*}
	 * @param id
	 */
	getSaleRecordDetail(id = '') {
		return fetch(
			'/distribution/crm/reader/member/get_reader_team_sale_record_detail',
			{
				params: { id },
				headers: {
					v2: true
				}
			}
		)
	},

	/**
	 * @description 获取普通领读者列表
	 * @param params
	 * @returns {*}
	 */
	getNormalReaderList(params = {}) {
		return fetch(`/distribution/crm/reader/member/normal_reader_list`, {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 收益团队销售
	 * @param params
	 * @returns {*}
	 */
	getReaderSaleList(params = {}) {
		return fetch(`/distribution/crm/performance/page/reader_sale`, {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取团队数据
	 * @param userId
	 * @returns {*}
	 */
	getCrmReaderTeamInfo(userId = '') {
		return fetch(`/distribution/crm/reader/member/get_crm_reader_team_info`, {
			params: { userId },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 分会/推广人
	 * @param params
	 * @returns {*}
	 */
	getTeamMemberPage(params = {}) {
		return fetch(`/distribution/crm/team_member/page`, {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 资深领读者导出
	 * @param params
	 * @returns {*}
	 */
	exportSeniorReaderList(params = {}) {
		return fetch(`/distribution/crm/reader/member/export_senior_reader_list`, {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 普通领读者导出
	 * @param params
	 * @returns {*}
	 */
	exportNormalReaderList(params = {}) {
		return fetch(`/distribution/crm/reader/member/export_normal_reader_list`, {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	}
}

const UserService = {
	/**
	 * @desc 获取园所列表
	 * @param 
	 */
	getKindergartenList(params={}) {
		return fetch(`user/crm/kindergarten/list`, {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取园所信息
	 * @param {*} userId
	 */
	getKindergartenInfo(userId = '') {
		return fetch(`user/crm/kindergarten/info?userId=${userId}`, {
			headers: {
				v2: true
			}
		})
	},


	/**
	 * @desc 获取园所会员列表
	 */
	getKindergartenMemberInfo(params={}) {
		return fetch(`/user/crm/kindergarten/member_list`, {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取园所兑换码详情
	 */
	getExchangeCodeCounter() {
		return fetch(`/user/crm/kindergarten/exchange_code/counter`, {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取园所兑换码列表
	 */
	getExchangeCodeList(params={}) {
		return fetch(`/user/crm/kindergarten/exchange_code/list`, {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},
	
	/**
	 * @desc 获取订阅卡卡库存信息
	 * @param {*} userId
	 */
	getCardStockInfo(userId = '') {
		return fetch(`/user/crm/user/card_stock_info/${userId}`, {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取会员信息
	 * @param {*} userId
	 */
	getMemberInfo(userId = '') {
		return fetch(`/user/crm/user/user_info/${userId}`, {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取订阅服务信息
	 * @param {*} userId
	 */
	getUserServiceInfo(userId = '') {
		return fetch(`/user/crm/user/user_service_info/${userId}`, {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取crm超管权限检查
	 */
	checkCrmSuperAuth() {
		return fetch('/user/crm/user/crmSuperMan', {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 超管查询用户
	 * @param {*} phone
	 */
	searchUserBySuper(phone = '') {
		return fetch('/user/crm/user/super/search', {
			params: { phone },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 跟单搜索用户
	 * @param {string} [phone='']
	 * @returns
	 */
	searchUserByTeam(phone = '') {
		return fetch('/user/crm/user/team/search', {
			params: { phone },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 超管切换用户
	 * @param {*} accountId
	 */
	swapTokenBySuper(accountId = '') {
		return fetch('/user/crm/user/super/swap_token', {
			params: { accountId },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 超管退回自己账号
	 */
	superToSelf() {
		return fetch('/user/crm/user/super/to_self', {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 临时绑定会员
	 * @param {*} needSubscribe
	 */
	invitationRecords(needSubscribe = false) {
		return fetch(`/user/share/invitation_records`, {
			params: { needSubscribe },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取平级线下进卡数据汇总
	 * @param {string} [date='']
	 * @returns
	 */
	getEqualTransferInfo(date = '') {
		return fetch('/user/crm/equal/transfer/info', {
			params: { date },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取平级线下进卡数据列表
	 * @param {*} [params={}]
	 * @returns
	 */
	equalTransferRecord(params = {}) {
		return fetch('/user/crm/equal/transfer/record', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 企业微信授权登录
	 * @param params
	 * @returns {undefined}
	 */
	corpLogin(params = {}) {
		return fetch(`/user/crm/user/corp/login`, {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @desc 获取crm我的客户列表
	 * @param params
	 * @returns {undefined}
	 */
	getCorpUserExternalContactList(params = {}) {
		return fetch('/user/crm/user/corp/user_external_contactList', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取用户打卡情况
	 * @param userId
	 * @returns {*}
	 */
	getUserClockInInfo(userId = '') {
		return fetch(`/user/crm/user_dynamic/clock_in_info/${userId}`, {
			headers: {
				v2: true
			}
		})
	},
	/**
	 * @description 获取用户回本进度
	 */
	getUserPayOffPeriod() {
		return fetch(`/user/crm/user/user_payoff_period`, {
			headers: {
				v2: true
			}
		})
	},
	/**
	 * @description 线下转卡预估收益明细列表
	 * @param {*} param
	 */
	getTransferAwardRecord(param = {}) {
		return fetch('/user/crm/transfer/card/get_transfer_award_list', {
			params: { ...param },
			headers: {
				v2: true
			}
		})
	},
	/**
	 * @description 线下转卡预估收益详情
	 * @param {*} transferCardRecordId
	 */
	getTransferAwardInfo(transferCardRecordId = '') {
		return fetch(
			`/user/crm/transfer/card/transfer_award_info/${transferCardRecordId}`,
			{
				headers: {
					v2: true
				}
			}
		)
	},

	/**
	 * @description 导出线下收益接口
	 * @param params
	 * @returns {*}
	 */
	exportOfflineAwardList(params = {}) {
		return fetch('/user/crm/download/award_list', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	}
}

const GoodsService = {
	/**
	 * @desc 获取用户详情的2680信息
	 * @param {string} [userId='']
	 * @returns
	 */
	getCrmCollegeInfo(userId = '') {
		return fetch(`/goods/crm/college/info/${userId}`)
	},
	/**
	 * @description 获取所有训练营的列表
	 * @returns {*}
	 */
	getCampaignList() {
		return fetch('/goods/crm/campaign/list')
	},

	/**
	 * @description 获取录课列表
	 * @returns {*}
	 */
	getRecordCourseList() {
		return fetch('/goods/crm/campaign/record_course_list')
	},

	/**
	 * @description 获取录课详情
	 * @param subCourseId
	 * @returns {*}
	 */
	getCourseFileRecordList(subCourseId) {
		return fetch(`/goods/crm/campaign/course_file_record_list/${subCourseId}`)
	},

	/**
	 * @description 开始录课
	 * @param subCourseId
	 * @returns {*}
	 */
	startingCourseRecording(subCourseId) {
		return fetch(`/goods/crm/campaign/starting_course/${subCourseId}`)
	},

	/**
	 * @description 更新录课的内容
	 * @param params
	 * @returns {*}
	 */
	updateSubCourseFileContext(params = {}) {
		return fetch('/goods/crm/campaign/sub_course_file_update_context', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 更新录课排序
	 * @param params
	 * @returns {*}
	 */
	updateSubCourseFileDisplay(params = {}) {
		return fetch('/goods/crm/campaign/sub_course_file_update_display', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 删除录课记录
	 * @param subCourseFileId
	 * @returns {*}
	 */
	deleteSubCourseFile(subCourseFileId) {
		return fetch(
			`/goods/crm/campaign/delete_sub_course_file/${subCourseFileId}`,
			{
				method: 'POST'
			}
		)
	},

	/**
	 * @description 录课详情确认内容
	 * @param subCourseId
	 * @returns {*}
	 */
	confirmSubCourseFile(subCourseId) {
		return fetch(`/goods/crm/campaign/confirm_sub_course_file/${subCourseId}`)
	},

	/**
	 * @desc 更新训练营子课列表
	 * @param params
	 * @returns {*}
	 */
	updateSubCourseFileList(params = {}) {
		return fetch(`/goods/bms/campaign_course/update_sub_course_file_list`, {
			method: 'POST',
			data: { ...params }
		})
	}
}

const OrderService = {
	/**
	 * @description  实习领读者详情
	 * @returns {*}
	 * @param orderCode
	 */
	getInternRevenueOrderCodeDetail(orderCode = '') {
		return fetch(`/order/app/order/get_order_code_detail`, {
			params: { orderCode },
			headers: {
				v2: true
			}
		})
	},
	/**
	 * 获取看板可用谷粒
	 * @param {*} params
	 */
	getCerealDetail(params = {}) {
		return fetch('/order/crm/cereal/detail', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},
	/**
	 * 获取谷粒详情
	 * @param {*} params
	 */
	getCerealRecord(params = {}) {
		return fetch('/order/crm/cereal/record', {
			params: { ...params },
			headers: {
				v2: true
			}
		})
	},
	/**
	 * @description 收益谷粒列表
	 * @param {*} [params={}]
	 * @return {*}
	 */
	getCerealAwardRecord(params = {}) {
		return fetch('/order/crm/cereal/get_cereal_record_list', {
			method: 'POST',
			data: { ...params },
			headers: {
				v2: true
			}
		})
	},
	/**
	 * @description 收益谷粒详情
	 * @param {*} cerealRecordId
	 * @return {*}
	 */
	getCerealAwardRecordInfo(cerealRecordId = '') {
		return fetch(`/order/crm/cereal/cereal_record_info/${cerealRecordId}`, {
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取课程订单详情
	 * @param orderCode
	 * @returns {*}
	 */
	getCampaignOrderInfo(orderCode = '') {
		return fetch(`/order/crm/camp_order/get_campaign_order_info`, {
			params: { orderCode },
			headers: {
				v2: true
			}
		})
	},

	/**
	 * @description 获取课程动态
	 * @returns {*}
	 * @param userId
	 */
	getCampaignOrderList(userId = '') {
		return fetch(`/order/crm/camp_order/user_campaign_order_list/${userId}`, {
			headers: {
				v2: true
			}
		})
	}
}

const ThirdPartyService = {
	exportDataSource(params = {}) {
		return fetch(`/thirdparty/crm/export`, {
			method: 'POST',
			data: { ...params }
		})
	}
}
export {
	CommonService,
	DistributionService,
	UserService,
	GoodsService,
	OrderService,
	ThirdPartyService
}
